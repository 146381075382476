import { Grid, Stack, Typography } from '@krakentech/coral';

import DirectDebitActionCard from '@/components/overview/DirectDebitActionCard';
import HomeMoveCard from '@/components/overview/HomeMoveCard';
import LatestBillCard from '@/components/overview/LatestBillCard';
import MakeAOneOffPaymentCard from '@/components/overview/MakeAOneOffPaymentCard';
import MeterActionCard from '@/components/overview/MeterActionCard';
import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';
import { useHasNotMovedOut } from '@/hooks/moveHome/useHasNotMovedOut';
import { useWillNotMoveOutInFuture } from '@/hooks/moveHome/useWillNotMoveOutInFuture';
import { useLatestReading } from '@/hooks/usage/useLatestReading';
import { useFeatureFlag } from '@/hooks/utils/useFeatureFlags';
import { FeatureNames } from '@/types/features';

const QuickLinks = () => {
  const { data: hasNotMovedOut } = useHasNotMovedOut();
  const { data: willNotMoveOutInTheFuture } = useWillNotMoveOutInFuture();
  const { accountNumber } = useAccountNumberContext();
  const { data: latestReadingData } = useLatestReading({ accountNumber });

  const showDirectDebitActionCard =
    useFeatureFlag(FeatureNames.DirectDebit) &&
    hasNotMovedOut &&
    willNotMoveOutInTheFuture;

  const showOnlinePayment = useFeatureFlag(FeatureNames.OnlinePayment);

  const showHomeMoveCard =
    useFeatureFlag(FeatureNames.HomeMove) &&
    hasNotMovedOut &&
    willNotMoveOutInTheFuture;

  const activeWaterMeters =
    latestReadingData?.properties[0]?.activeWaterMeters.length || 0;

  const hasOneActiveMeter = activeWaterMeters === 1;

  const isInMovingProcess = !hasNotMovedOut || !willNotMoveOutInTheFuture;

  const isInMovingProcessWithoutActiveMeter =
    !activeWaterMeters && isInMovingProcess;

  const showMeterActionCard =
    !isInMovingProcessWithoutActiveMeter &&
    (hasOneActiveMeter || !activeWaterMeters);

  return (
    <Stack gap="sm" md={{ gap: 'md' }} direction="vertical">
      <Typography variant="h2">Quick links</Typography>

      <Grid
        templateColumns={'repeat(1, 1fr)'}
        gap="sm"
        md={{
          gap: 'md',
          templateColumns: 'repeat(1, 1fr)',
        }}
        lg={{
          templateColumns: 'repeat(2, 1fr)',
        }}
      >
        {showDirectDebitActionCard && <DirectDebitActionCard />}
        {showOnlinePayment && <MakeAOneOffPaymentCard />}
        <LatestBillCard />
        {showMeterActionCard && <MeterActionCard />}
        {showHomeMoveCard && <HomeMoveCard />}
      </Grid>
    </Stack>
  );
};

export default QuickLinks;
