import ApplyForMeterCard from '@/components/overview/ApplyForMeterCard';
import SubmitNewReadingCard from '@/components/overview/SubmitNewReadingCard';
import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';
import { useLatestReading } from '@/hooks/usage/useLatestReading';

const MeterActionCard = () => {
  const { accountNumber } = useAccountNumberContext();

  const {
    data,
    isLoading: isLatestReadingLoading,
    isError: isLatestReadingError,
  } = useLatestReading({ accountNumber });

  const isMetered = !!data?.properties[0]?.activeWaterMeters.length;

  if (isMetered) {
    return (
      <SubmitNewReadingCard
        meterId={data.properties[0].activeWaterMeters[0].id}
        isLoading={isLatestReadingLoading}
        isError={isLatestReadingError}
      />
    );
  }

  return (
    <ApplyForMeterCard
      isLoading={isLatestReadingLoading}
      isError={isLatestReadingError}
    />
  );
};

export default MeterActionCard;
