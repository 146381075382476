import { Grid } from '@krakentech/coral';
import { IconDroplet } from '@krakentech/icons';

import { ActionCard } from '@/components';
import { useEligibleForWaterMeterInstallation } from '@/hooks/accounts/useEligibleForWaterMeterInstallation';
import { useSyncFeatureFlag } from '@/hooks/utils/useFeatureFlags';
import { FeatureNames } from '@/types/features';
import { INTERNAL_PATHS } from '@/utils/urls';

type ApplyForMeterCardProps = {
  isLoading: boolean;
  isError: boolean;
};

const ApplyForMeterCard = ({
  isLoading: isLatestReadingLoading,
  isError: isLatestReadingError,
}: ApplyForMeterCardProps) => {
  const applyForMeterOverviewLink =
    INTERNAL_PATHS.APPLY_FOR_METER_OVERVIEW.path;

  const applyForMeterEnabled = useSyncFeatureFlag(FeatureNames.ApplyForMeter);

  const meterInstallationLink =
    process.env.NEXT_PUBLIC_METER_INSTALLATION_LINK ?? '';

  const href = applyForMeterEnabled
    ? applyForMeterOverviewLink
    : meterInstallationLink;

  const {
    data: eligibleForWaterMeterInstallation,
    isLoading: isLoadingEligibleForWaterMeterInstallation,
    isError: isErrorEligibleForWaterMeterInstallation,
  } = useEligibleForWaterMeterInstallation();

  // Determine overall loading as a combination of all loading states
  const isLoadingCombined =
    isLatestReadingLoading || isLoadingEligibleForWaterMeterInstallation;

  // Determine overall error as a combination of all error states
  const isErrorCombined =
    isLatestReadingError || isErrorEligibleForWaterMeterInstallation;

  // Return null if eligibleForMeterInstallation is false or if both applyForMeterEnabled is false and meterInstallationLink is empty
  if (
    !eligibleForWaterMeterInstallation ||
    (!applyForMeterEnabled && !meterInstallationLink)
  ) {
    return null;
  }

  return (
    <Grid.Item>
      <ActionCard
        href={href}
        title="Switch to a water meter"
        titleComponent="p"
        icon={<IconDroplet size={32} />}
        isLoading={isLoadingCombined}
        isError={isErrorCombined}
        openInNewTab={!applyForMeterEnabled}
      />
    </Grid.Item>
  );
};

export default ApplyForMeterCard;
