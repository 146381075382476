import { useHasActiveWaterMeter } from '@/hooks/usage/useHasActiveWaterMeter';

// Placeholder function for missing hook
const useHasAssessedTariff = () => ({
  data: false,
  isError: false,
  isLoading: false,
});

// Placeholder function for missing hook
const useHasWaterMeterFittingAppointment = () => ({
  data: false,
  isError: false,
  isLoading: false,
});

export const useEligibleForWaterMeterInstallation = () => {
  const {
    data: activeWaterMeterCount,
    isError: isErrorActiveWaterMeter,
    isLoading: isLoadingActiveWaterMeter,
  } = useHasActiveWaterMeter();

  const {
    data: hasAssessedTariff,
    isError: isErrorAssessedTariff,
    isLoading: isLoadingAssessedTariff,
  } = useHasAssessedTariff();

  const {
    data: hasWaterMeterFittingAppointment,
    isError: isErrorWaterMeterFittingAppointment,
    isLoading: isLoadingWaterMeterFittingAppointment,
  } = useHasWaterMeterFittingAppointment();

  const isMetered = activeWaterMeterCount && activeWaterMeterCount > 0;

  // Determine overall loading as a combination of all loading states
  const isLoading =
    isLoadingActiveWaterMeter ||
    isLoadingAssessedTariff ||
    isLoadingWaterMeterFittingAppointment;

  // Determine overall error as a combination of all error states
  const isError =
    isErrorActiveWaterMeter ||
    isErrorAssessedTariff ||
    isErrorWaterMeterFittingAppointment;

  // Ensure eligibility is only computed when loading is false
  const data =
    !isLoading &&
    !isMetered &&
    !isError &&
    !hasAssessedTariff &&
    !hasWaterMeterFittingAppointment;

  return {
    data,
    isLoading,
    isError,
  };
};
